import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import { universalPostRequest } from '../../use/universalRequests';

const { REACT_APP_API_BASE_URL = '' } = process.env;

const TOAST_POSITION: ToastPosition = 'top-center';

export interface RecoverPasswordMethods {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

export interface RecoverPasswordResponse {
  message: string;
}

export interface RecoverPasswordPayload {
  email: string;
  tenant: string;
}

export const recoverPassword = async (
  payload: RecoverPasswordPayload,
  methods: RecoverPasswordMethods,
) => {
  const { setLoading, setErrorMessage, setSuccessMessage } = methods;
  setLoading(true);

  const id = toast.loading('Sending recovery email...', {
    position: TOAST_POSITION,
  });

  const url = `${REACT_APP_API_BASE_URL}/auth/recover-password`;

  const response = await universalPostRequest<
    RecoverPasswordResponse,
    RecoverPasswordPayload
  >(url, payload);
  if (response && response.data && response.status === 200) {
    setErrorMessage('');
    setSuccessMessage(response.data.message);
    toast.update(id, {
      render: response.data.message,
      type: 'success',
      isLoading: false,
      autoClose: 2000,
      position: TOAST_POSITION,
    });
  } else {
    const { error = 'Something went wrong. Please try again.' } = response;
    setErrorMessage(error);

    toast.update(id, {
      render: error,
      type: 'error',
      isLoading: false,
      autoClose: 2000,
      position: TOAST_POSITION,
    });
  }

  setLoading(false);
};
