import axios from 'axios';

interface GenericResponseInterface<T> {
  status: number;
  data: T;
  code?: string;
  error?: string;
  mfaSecretCode?: string;
}

interface AxiosParamInterface {
  headers?: object;
  params?: object;
}

interface ErrorResponse {
  code: string;
  error: string;
}

axios.interceptors.response.use(
  undefined,
  (error) => error.response.data as ErrorResponse,
);

export const universalGetRequest = async <ResponseType>(
  url: string,
  params?: AxiosParamInterface,
) => {
  try {
    const response: GenericResponseInterface<ResponseType> = await axios.get(
      url,
      {
        ...params,
      },
    );

    return response;
  } catch (error: any) {
    return error as GenericResponseInterface<ResponseType>;
  }
};

export const universalPostRequest = async <ResponseType, PostDataType>(
  url: string,
  data: PostDataType,
  params?: AxiosParamInterface,
) => {
  try {
    const response: GenericResponseInterface<ResponseType> = await axios.post(
      url,
      { ...data },
      { ...params },
    );

    return response;
  } catch (error: any) {
    return error as GenericResponseInterface<ResponseType>;
  }
};
