import { Button } from '@cuttingroom/core-components';
import React, { useContext, useState } from 'react';
import EmailInput from './input-components/EmailInput';
import MFAInput from './input-components/MFAInput';
import PasswordInput from './input-components/PasswordInput';
import SetupMFA from './input-components/SetupMFA';
import WorkspaceInput from './input-components/WorkspaceInput';
import { LoginContext } from './LoginProvider';
import * as config from './utils/interface';
import { login } from './utils/utility';

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [secretCode, setSecretCode] = useState('');

  const {
    step,
    setStep,
    setSsoConfig,
    email,
    password,
    setEmail,
    setPassword,
    workspace,
    setWorkspace,
    workspaceExpanded,
    setWorkspaceExpanded,
  } = useContext(LoginContext);

  console.log('step to be rendered: ', step);

  const loginHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const payload = {
      email,
      password,
      tenant: workspace,
      mfaCode,
    };
    const methods = {
      setStep,
      setSsoConfig,
      setLoading,
      setErrorMessage,
      setSecretCode,
    };

    login(step, payload, methods);
  };

  return (
    <form className="login-form">
      {[config.EMAIL_WORKSPACE_INPUT, config.PASSWORD_INPUT].includes(step) && (
        <EmailInput
          {...{
            email,
            setEmail,
            workspace,
            workspaceExpanded,
            setWorkspaceExpanded,
          }}
        />
      )}

      {step === config.EMAIL_WORKSPACE_INPUT && workspaceExpanded && (
        <WorkspaceInput
          {...{
            workspace,
            setWorkspace,
            workspaceExpanded,
            setWorkspaceExpanded,
          }}
        />
      )}

      {step === config.PASSWORD_INPUT
        && workspaceExpanded
        && workspace.length > 0 && (
          <WorkspaceInput
            {...{
              workspace,
              setWorkspace,
              workspaceExpanded,
              setWorkspaceExpanded,
            }}
          />
      )}

      {step === config.PASSWORD_INPUT && (
        <PasswordInput {...{ password, setPassword, errorMessage }} />
      )}

      {step === config.MFA_CODE_MISSING && (
        <MFAInput
          {...{ mfaCode, setMfaCode, loading, errorMessage, loginHandler }}
        />
      )}

      {step === config.MFA_SETUP_REQUIRED && (
        <SetupMFA
          {...{
            email,
            secretCode,
            mfaCode,
            setMfaCode,
            setPassword,
            loading,
            errorMessage,
            loginHandler,
          }}
        />
      )}

      {[config.EMAIL_WORKSPACE_INPUT, config.PASSWORD_INPUT].includes(step) && (
        <Button
          type="submit"
          className="button primary"
          disabled={
            email.length === 0
            || (step === config.PASSWORD_INPUT && password.length === 0)
            || loading
          }
          inProgress={loading}
          onClick={loginHandler}
        >
          {(step === config.EMAIL_WORKSPACE_INPUT && 'Continue') || 'Sign in'}
        </Button>
      )}
    </form>
  );
};

export default LoginForm;
