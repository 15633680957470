import { Button, Input } from '@cuttingroom/core-components';
import React, { useEffect, useState } from 'react';
import { generateQRCodeUrl } from '../utils/utility';

interface SetupMFAProps {
  email: string;
  secretCode: string;
  mfaCode: string;
  setMfaCode: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  errorMessage: string;
  loginHandler: (_event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SetupMFA: React.FC<SetupMFAProps> = ({
  email,
  secretCode,
  mfaCode,
  setMfaCode,
  setPassword,
  loading,
  errorMessage,
  loginHandler,
}) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    generateQRCodeUrl(email, secretCode, setSrc);
  }, [email, secretCode, setPassword]);

  return (
    <div className="qrcode-div">
      <img width="212" height={212} src={src} alt="QR code" />

      <Input
        className="mfa dark"
        label="Scan and enter one-time code"
        type="text"
        autoFocus
        clearable={false}
        errorMessage={errorMessage.length > 0 ? errorMessage : ''}
        value={mfaCode}
        required
        onChange={(e: { target: { value: string } }) => setMfaCode(e.target.value)}
      />

      <Button
        type="submit"
        className="button primary"
        disabled={mfaCode.length !== 6 || loading}
        inProgress={loading}
        onClick={loginHandler}
      >
        Verify
      </Button>
    </div>
  );
};

export default SetupMFA;
