import { Button, Input, Notice } from '@cuttingroom/core-components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableText from '../common/ClickableText';
import LandingPageBG from '../LandingPageBG/LandingPageBG';
import './style.scss';
import { recoverPassword } from './utility';

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="success-message">
      <h1>
        Password recovery instructions has been sent to your email. Please check inbox and follow the steps to recover your password
      </h1>

      <Button className="button primary" onClick={() => navigate('../login', { replace: true })}>Login</Button>
    </div>
  );
};

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [tenant, setTenant] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const recoverPasswordHandler = (
    event: React.ChangeEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    const payload = { email, tenant };
    const methods = { setLoading, setErrorMessage, setSuccessMessage };

    recoverPassword(payload, methods);
  };

  return (
    <LandingPageBG>
      {successMessage ? (
        <SuccessMessage />
      ) : (
        <form
          className="recover-pass-input-form"
          onSubmit={recoverPasswordHandler}
        >
          <h3 style={{ margin: '1rem 0' }}>Recover password</h3>

          <Input
            className="email dark"
            label="Email"
            type="email"
            autoFocus
            required
            clearable={false}
            value={email}
            onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
          />

          <Input
            className="workspace dark"
            label="Workspace"
            type="text"
            clearable={false}
            value={tenant}
            onChange={(e: { target: { value: string } }) => setTenant(e.target.value)}
          />

          <Notice infoMessage="You can enter your email and workspace to recover your forgotten or expired password." />
          {errorMessage && <Notice errorMessage={errorMessage} />}

          <ClickableText
            title="Back to login"
            action={() => navigate('/login')}
          />

          <Button
            type="submit"
            className="button primary"
            disabled={!email.length || !tenant.length || loading}
            loading={loading}
          >
            Recover
          </Button>
        </form>
      )}
    </LandingPageBG>
  );
};

export default RecoverPassword;
