import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import { universalPostRequest } from '../../use/universalRequests';

const { REACT_APP_API_BASE_URL = '' } = process.env;
const TOAST_POSITION: ToastPosition = 'top-center';

interface ActivateAccountMethods {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface ActivateAccountResponse {
  message: string;
}

interface ActivateAccountPayload {
  email: string;
  tenant: string;
  password: string;
  newPassword: string;
}

export const activateAccount = async (
  payload: ActivateAccountPayload,
  methods: ActivateAccountMethods,
) => {
  const { setLoading, setErrorMessage, setSuccessMessage } = methods;
  setLoading(true);

  const url = `${REACT_APP_API_BASE_URL}/auth/login`;
  const id = toast.loading('Activating your account...', {
    position: TOAST_POSITION,
  });

  const response = await universalPostRequest<
    ActivateAccountResponse,
    ActivateAccountPayload
  >(url, payload);
  if (response && response.data && response.status === 200) {
    setSuccessMessage('Account activated');
    toast.update(id, {
      render: 'Congratulations! Your account has been activated',
      type: 'success',
      isLoading: false,
      autoClose: 2000,
      position: TOAST_POSITION,
    });
  } else {
    const { code = '', error = '' } = response;
    if (code === 'MFA_SETUP_REQUIRED') {
      setSuccessMessage('Account activated');
      toast.update(id, {
        render: 'Congratulations! Your account has been activated',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
        position: TOAST_POSITION,
      });
    } else {
      setErrorMessage(error);
      toast.update(id, {
        render: `${error}`,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
        position: TOAST_POSITION,
      });
    }
  }

  setLoading(false);
};
