/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useState } from 'react';
import LoginForm from './LoginForm';
import { LoginContext } from './LoginProvider';
import SSOLogin from './SSOLogin';
import * as config from './utils/interface';
import './style.scss';

const chevron = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.41 19L16 14.42L20.59 19L22 17.59L16 11.59L10 17.59L11.41 19Z" fill="white" />
  </svg>
);

const LoginFormWrapper: React.FC = () => {
  const { step, ssoConfig } = useContext(LoginContext);
  const [loginFormExpanded, setLoginFormExpanded] = useState(false);

  return (
    <div className="login-wrapper">
      {(step !== config.PASSWORD_INPUT || !ssoConfig) && <LoginForm />}

      {step === config.PASSWORD_INPUT && ssoConfig && <SSOLogin />}

      {step === config.PASSWORD_INPUT && ssoConfig && (
      <div className={`secondary-login-form form-${loginFormExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="secondary-login-form-header" onClick={() => setLoginFormExpanded(!loginFormExpanded)}>
          <span className="core-icon">{chevron}</span>
          Sign in with email and password
        </div>
        <div className="content">
          <LoginForm />
        </div>
      </div>
      )}

    </div>
  );
};

export default LoginFormWrapper;
