import { Button } from '@cuttingroom/core-components';
import React, { useContext, useEffect, useState } from 'react';
import { LoginContext } from './LoginProvider';

const SSOLogin: React.FC = () => {
  const { ssoConfig } = useContext(LoginContext);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (ssoConfig) {
      setUrl(encodeURI(`https://${ssoConfig.domain}.auth.${ssoConfig.region}.amazoncognito.com/oauth2/authorize?identity_provider=${ssoConfig.providers[0].name}&redirect_uri=${ssoConfig.callbackUrl}&response_type=CODE&client_id=${ssoConfig.clientId}&scope=email openid profile`));
    }
  }, [ssoConfig]);

  return (
    <div className="sso-login">
      {ssoConfig && (
        <>
          <p>Keep it simple, use your company&#39;s single sign on</p>
          <Button type="button" onClick={() => window.location.replace(url)}>
            SIGN IN WITH
            {' '}
            {ssoConfig.providers[0].name}
          </Button>
        </>
      )}
    </div>
  );
};

export default SSOLogin;
