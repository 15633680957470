import { Button, Input } from '@cuttingroom/core-components';
import React from 'react';

interface MFAInputProps {
  mfaCode: string;
  setMfaCode: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  errorMessage: string;
  loginHandler: (_event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MFAInput: React.FC<MFAInputProps> = ({
  mfaCode,
  setMfaCode,
  loading,
  errorMessage,
  loginHandler,
}) => (
  <>
    <Input
      className="mfa dark"
      label="One time code"
      type="text"
      autoFocus
      clearable={false}
      errorMessage={errorMessage.length > 0 ? errorMessage : ''}
      value={mfaCode}
      required
      onChange={(e: { target: { value: string } }) => setMfaCode(e.target.value)}
    />

    <Button
      type="submit"
      className="button primary"
      disabled={mfaCode.length !== 6 || loading}
      inProgress={loading}
      onClick={loginHandler}
    >
      Verify
    </Button>
  </>
);

export default MFAInput;
