import { Input } from '@cuttingroom/core-components';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableText from '../../common/ClickableText';
import { LoginContext } from '../LoginProvider';
import * as config from '../utils/interface';

interface PasswordInputProps {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  errorMessage,
}) => {
  const { setStep } = useContext(LoginContext);
  const navigate = useNavigate();

  return (
    <>
      <Input
        className="password dark"
        label="Password"
        type="password"
        clearable={false}
        value={password}
        autoFocus
        errorMessage={errorMessage.length > 0 ? errorMessage : ''}
        required
        onChange={(e: { target: { value: string } }) => setPassword(e.target.value)}
      />

      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ClickableText
          title="Back to previous screen"
          action={() => setStep(config.EMAIL_WORKSPACE_INPUT)}
        />

        <ClickableText
          title="Forgot password?"
          action={() => navigate('/recover-password')}
        />
      </p>
    </>
  );
};

export default PasswordInput;
