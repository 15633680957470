import { Input } from '@cuttingroom/core-components';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableText from '../../common/ClickableText';
import { LoginContext } from '../LoginProvider';
import { EMAIL_WORKSPACE_INPUT, PASSWORD_INPUT } from '../utils/interface';

interface WorkspaceInputProps {
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
  workspaceExpanded: boolean;
  setWorkspaceExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceInput: React.FC<WorkspaceInputProps> = ({
  workspace,
  setWorkspace,
  workspaceExpanded,
  setWorkspaceExpanded,
}) => {
  const { step } = useContext(LoginContext);
  const navigate = useNavigate();

  return (
    <>
      <Input
        className="workspace dark"
        label="Workspace"
        type="text"
        clearable={false}
        value={workspace}
        locked={step === PASSWORD_INPUT}
        onChange={(e: { target: { value: string } }) => setWorkspace(e.target.value)}
      />

      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        {step === EMAIL_WORKSPACE_INPUT && (
          <>
            <ClickableText
              title="Use default workspace"
              action={() => {
                setWorkspaceExpanded(!workspaceExpanded);
                setWorkspace('');
              }}
            />
            <ClickableText
              title="Forgot password?"
              action={() => navigate('/recover-password')}
            />
          </>
        )}
      </p>
    </>
  );
};

export default WorkspaceInput;
