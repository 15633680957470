import React from 'react';
import './style.scss';

type CallbackFunction = () => void;

interface ClickableTextProps {
  title: string;
  action: CallbackFunction;
}

const ClickableText: React.FC<ClickableTextProps> = ({ title, action }) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
  <p className="clickable-text" onClick={() => action()}>
    {title}
  </p>
);

export default ClickableText;
