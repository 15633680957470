import React from 'react';
import { universalGetRequest } from '../../use/universalRequests';
import { LoginResponseData } from '../LoginScreen/utils/interface';

const { REACT_APP_API_BASE_URL = '' } = process.env;

interface VerifyTokenResponse {
  authenticated: boolean;
}

export const isAuthenticated = async (
  token: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setLoading(true);
  const url = `${REACT_APP_API_BASE_URL}/auth/verify-token`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const response = await universalGetRequest<VerifyTokenResponse>(url, config);
    setLoading(false);

    return response.data.authenticated;
  } catch (err) {
    console.log('Error occurred: ', err);
  }

  setLoading(false);
  return false;
};

export const getAuthInfoFromLocalStorage = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}';
  return JSON.parse(authInfo) as LoginResponseData;
};
