import React from 'react';

export const EMAIL_WORKSPACE_INPUT = 'EMAIL_WORKSPACE_INPUT';
export const PASSWORD_INPUT = 'PASSWORD_INPUT';
export const MFA_CODE_MISSING = 'MFA_CODE_MISSING';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const MFA_SETUP_REQUIRED = 'MFA_SETUP_REQUIRED';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_WITH_CODE = 'RECOVER_PASSWORD_WITH_CODE';

export type LoginStepType =
  | typeof EMAIL_WORKSPACE_INPUT
  | typeof PASSWORD_INPUT
  | typeof MFA_CODE_MISSING
  | typeof NEW_PASSWORD_REQUIRED
  | typeof MFA_SETUP_REQUIRED
  | typeof RECOVER_PASSWORD
  | typeof RECOVER_PASSWORD_SUCCESS
  | typeof RECOVER_PASSWORD_WITH_CODE;

type SSOProviders = {
  name: string;
  createdAt: string;
  type: string;
  updatedAt: string;
};

export type SSOConfig = {
  callbackUrl: string;
  clientId: string;
  region: string;
  providers: SSOProviders[];
  domain: string;
};

export interface ContextInterface {
  step: LoginStepType;
  setStep: React.Dispatch<React.SetStateAction<LoginStepType>>;
  ssoConfig: SSOConfig | false;
  setSsoConfig: React.Dispatch<React.SetStateAction<SSOConfig | false>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  workspace: string;
  setWorkspace: React.Dispatch<React.SetStateAction<string>>;
  workspaceExpanded: boolean;
  setWorkspaceExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UnifiedUserResponseDataInterface {
  ssoConfig: SSOConfig | false;
}

export type UserResponseObj = {
  name: string;
  email: string;
  roles: string[];
  permissions: string[];
};

export type LoginResponseData = {
  accessToken: string;
  envName: string;
  message: string;
  tenant: string;
  user: UserResponseObj;
};

export type LoginResponseDataInterface = LoginResponseData;

export interface LoginPayload {
  email: string;
  password: string;
  tenant?: string;
  mfaCode?: string;
  newPassword?: string;
}

export interface LoginMethods {
  setStep: React.Dispatch<React.SetStateAction<LoginStepType>>;
  setSsoConfig: React.Dispatch<React.SetStateAction<SSOConfig | false>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setSecretCode: React.Dispatch<React.SetStateAction<string>>;
}
