import { Button, Input, Notice } from '@cuttingroom/core-components';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LandingPageBG from '../LandingPageBG/LandingPageBG';
import './style.scss';
import { confirmRecoverPassword } from './utility';

const PASSWORD_POLICY = 'Minimum 8 characters. Needs to include at least one number, one uppercase and lowercase letter and one symbol.';

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className="success-message">
      <h1>
        Your password has been recovered. You can now login with your new password.
      </h1>

      <Button className="button primary" onClick={() => navigate('/login')}>Login</Button>
    </div>
  );
};

const RecoverPasswordConfirmation: React.FC = () => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('');
  const [tenant, setTenant] = useState('');

  const { search, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const queryEmail = searchParams.get('email');
    const queryTenant = searchParams.get('tenant');
    const queryCode = searchParams.get('code');

    if (queryEmail && queryTenant && queryCode) {
      setEmail(queryEmail);
      setTenant(queryTenant);
      setCode(queryCode);
    } else {
      navigate('/');
    }
  }, [hash, navigate, search]);

  const recoverPasswordHandler = (
    event: React.ChangeEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const payload = { email, tenant, code, password };
    const methods = { setLoading, setErrorMessage, setSuccessMessage };

    confirmRecoverPassword(payload, methods);
  };

  return (
    <LandingPageBG>
      {successMessage ? <SuccessMessage /> : (
        <form
          className="confirm-recover-pass-input-form"
          onSubmit={recoverPasswordHandler}
        >
          <h3 style={{ margin: '1rem 0' }}>Confirm recover password</h3>

          <Input
            className="email dark"
            label="Code"
            type="text"
            locked
            value={code}
            onChange={(e: { target: { value: string } }) => setCode(e.target.value)}
          />

          <Input
            className="password dark"
            label="Password"
            type="password"
            clearable={false}
            value={password}
            onChange={(e: { target: { value: string } }) => setPassword(e.target.value)}
          />

          <Notice infoMessage={PASSWORD_POLICY} />

          <Input
            className="password dark"
            label="Confirm password"
            type="password"
            clearable={false}
            value={confirmPassword}
            onChange={(e: { target: { value: string } }) => setConfirmPassword(e.target.value)}
          />

          {password.length > 0
          && confirmPassword.length > 0
          && password !== confirmPassword && (
            <Notice errorMessage="The passwords you have entered does not match each other." />
          )}

          {errorMessage && <Notice errorMessage={errorMessage} />}

          <Button
            type="submit"
            className="button primary"
            disabled={
            !password.length
            || !confirmPassword.length
            || password !== confirmPassword
            || loading
          }
            loading={loading}
          >
            Recover password
          </Button>
        </form>
      )}

    </LandingPageBG>
  );
};

export default RecoverPasswordConfirmation;
