import QRCode from 'qrcode';
import React from 'react';
import { toast, ToastPosition } from 'react-toastify';
import * as request from '../../../use/universalRequests';
import * as config from './interface';

const { REACT_APP_API_BASE_URL = '', REACT_APP_DOMAIN_NAME = '' } = process.env;

const TOAST_POSITION: ToastPosition = 'top-center';

export const generateRedirectUrl = (origin: string, tenant: string, environment: string) => {
  const prefix = origin.includes('beta') ? `${tenant}.beta` : tenant;
  return `https://${prefix}.${environment}.${REACT_APP_DOMAIN_NAME}`;
};

export const login = async (
  step: config.LoginStepType,
  payload: config.LoginPayload,
  methods: config.LoginMethods,
) => {
  const { email, password, tenant } = payload;
  const { setStep, setLoading, setSsoConfig, setSecretCode, setErrorMessage } = methods;

  setLoading(true);
  if (step === config.EMAIL_WORKSPACE_INPUT) {
    const url = `${REACT_APP_API_BASE_URL}/unified-user/ssoDetailsOfTenantByEmail/?email=${email}${
      tenant && `&tenant=${tenant}`
    }`;

    const response = await request.universalGetRequest<config.UnifiedUserResponseDataInterface>(
      encodeURI(url),
    );
    if (response && response.data && response.status === 200) {
      setStep(config.PASSWORD_INPUT);
      setSsoConfig(response.data.ssoConfig);
    }
  } else if (email.length && password.length) {
    const id = toast.loading('Logging you in...', {
      position: TOAST_POSITION,
    });

    const url = `${REACT_APP_API_BASE_URL}/auth/login`;

    const response = await request.universalPostRequest<
      config.LoginResponseDataInterface,
      config.LoginPayload
    >(url, payload);
    if (response && response.data && response.status === 200) {
      // eslint-disable-next-line no-shadow
      const { envName, tenant, accessToken, message } = response.data;

      localStorage.setItem('authInfo', JSON.stringify(response.data));

      const redirectUrl = `${generateRedirectUrl(window.location.origin, tenant, envName)}?accessToken=${accessToken}`;

      window.location.replace(redirectUrl);
      toast.update(id, {
        render: message,
        type: 'success',
        isLoading: false,
        autoClose: 2000,
        position: TOAST_POSITION,
      });
    } else {
      const { code = '', error = '', mfaSecretCode = '' } = response;
      setErrorMessage(error);

      if (code === config.MFA_CODE_MISSING) {
        setErrorMessage('');
        setStep(config.MFA_CODE_MISSING);
      } else if (code === config.NEW_PASSWORD_REQUIRED) {
        setStep(config.NEW_PASSWORD_REQUIRED);
      } else if (code === config.MFA_SETUP_REQUIRED) {
        setStep(config.MFA_SETUP_REQUIRED);
        setSecretCode(mfaSecretCode);
      }

      toast.update(id, {
        render: `${error}`,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
        position: TOAST_POSITION,
      });
    }
  }
  setLoading(false);
};

export const generateQRCodeUrl = (
  email: string,
  secretCode: string,
  setSrc: React.Dispatch<React.SetStateAction<string>>,
) => {
  QRCode.toDataURL(
    `otpauth://totp/${email}?secret=${secretCode}&issuer=${window.location.host}`,
  ).then(setSrc);
};
