import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isAuthenticated, getAuthInfoFromLocalStorage } from './utility';
import { generateRedirectUrl } from '../LoginScreen/utils/utility';
import Loader from '../common/Loader';

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isLogout = searchParams.get('logout');

  useEffect(() => {
    if (isLogout) localStorage.removeItem('authInfo');

    const authInfo = getAuthInfoFromLocalStorage();
    const { accessToken, envName, tenant } = authInfo;
    const isAuthenticatedHandler = async () => isAuthenticated(accessToken, setLoading);

    if (accessToken && tenant && envName) {
      isAuthenticatedHandler()
        .then((authenticated) => {
          if (authenticated) {
            const redirectUrl = `${generateRedirectUrl(
              window.location.origin,
              tenant,
              envName,
            )}?accessToken=${accessToken}`;
            window.location.replace(redirectUrl);
          } else {
            navigate('/login');
          }
        })
        .catch((err) => {
          console.log('Error occurred: ', err);
          navigate('/login');
        });
    } else {
      navigate('/login');
    }
  }, [navigate, isLogout]);

  return <div>{loading && <Loader />}</div>;
};

export default Home;
