import { Input } from '@cuttingroom/core-components';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ClickableText from '../../common/ClickableText';
import { LoginContext } from '../LoginProvider';
import { EMAIL_WORKSPACE_INPUT, PASSWORD_INPUT } from '../utils/interface';

interface EmailInputProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  workspace: string;
  workspaceExpanded: boolean;
  setWorkspaceExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailInput: React.FC<EmailInputProps> = ({
  email,
  setEmail,
  workspace,
  workspaceExpanded,
  setWorkspaceExpanded,
}) => {
  const { step } = useContext(LoginContext);
  const navigate = useNavigate();

  return (
    <div>
      <p>
        Please enter your email
        {step === PASSWORD_INPUT && ', password '}
        {workspaceExpanded && workspace.length > 0 && ' and workspace'}
        {' '}
        to
        access CuttingRoom
      </p>
      <Input
        className="email dark"
        label="Email"
        type="email"
        autoFocus
        required={step === EMAIL_WORKSPACE_INPUT}
        locked={step !== EMAIL_WORKSPACE_INPUT}
        clearable={false}
        value={email}
        onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
      />

      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        {step === EMAIL_WORKSPACE_INPUT && !workspaceExpanded && (
        <>
          <ClickableText
            title="Choose workspace"
            action={() => setWorkspaceExpanded(!workspaceExpanded)}
          />

          <ClickableText
            title="Forgot password?"
            action={() => navigate('/recover-password')}
          />
        </>
        )}
      </p>
    </div>
  );
};

export default EmailInput;
